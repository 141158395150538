@font-face {
  font-family: "Poppins-Medium";
  src: local("Poppins-Medium"),
    url("./fonts/Poppins-Medium.woff2") format("woff2"),
    url("./fonts/Poppins-Medium.woff") format("woff"),
    url("./fonts/Poppins-Medium.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Poppins";
  src: local("Poppins"), url("./fonts/Poppins-SemiBold.woff2") format("woff2"),
    url("./fonts/Poppins-SemiBold.woff") format("woff"),
    url("./fonts/Poppins-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Poppins-Light";
  src: local("Poppins-Light"),
    url("./fonts/Poppins-Light.woff2") format("woff2"),
    url("./fonts/Poppins-Light.woff") format("woff"),
    url("./fonts/Poppins-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: light;
  font-display: swap;
}

:root {
  --blanco: rgba(255, 255, 255, 1);
  --negro: rgba(0, 0, 0, 1);
  --cafeBase: rgba(123, 90, 19, 1);
  --cafeClaro: rgba(197, 162, 91, 1);
  --cafeOscuro: rgba(69, 51, 21, 1);
  --gris: rgba(69, 70, 80, 1);
  --grisClaro: #dee2e6;
  --crema: rgba(255, 237, 180, 1);
  --cremaMedio: rgba(255, 247, 216, 1);
  --cremaSuave: rgba(238, 237, 237, 1);
  --amarillo: rgba(222, 201, 26, 1);
  --rosa: rgba(227, 99, 199, 1);
  --error: #e4032d;
  --ok: #357f34;
  --Title: 600;
  --Link: 600;
  --Cuerpo: 400;
  --h1: 2.488rem;
  --h2: 2.074rem;
  --h3: 1.728rem;
  --h4: 1.44rem;
  --h5: 1.2rem;
  --h6: 0.7rem;
  --notification: 0.833rem;
  --bold: 700;
  --p: 14px;
}

body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--gris);
  background-color: var(--cremaMedio);
}

a {
  color: var(--cafeOscuro);
  text-decoration: none;
  font-size: var(--p);
  font-weight: var(--Link);
}

a:hover {
  color: var(--cafeOscuro);
}

.animLink:after {
  content: "";
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: var(--cafeOscuro);
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
}

.animLink:hover:after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

.animLink {
  display: inline-block;
  position: relative;
  color: var(--cafeOscuro);
  text-decoration: none;
}

h1 {
  font-family: "Poppins", sans-serif;
  font-size: var(--h1);
  font-weight: var(--Title);
}

h2 {
  font-family: "Poppins", sans-serif;
  font-size: var(--h2);
  font-weight: var(--Title);
}

h3 {
  font-family: "Poppins", sans-serif;
  font-size: var(--h3);
  font-weight: var(--Title);
}

h4 {
  font-family: "Poppins", sans-serif;
  font-size: var(--h4);
  font-weight: var(--Title);
}

h5 {
  font-family: "Poppins", sans-serif;
  font-size: var(--h5);
  font-weight: var(--Title);
}

h6 {
  font-family: "Poppins-Medium", sans-serif;
  font-size: var(--h6);
  font-weight: var(--Title);
}

p {
  font-family: "Poppins-Medium", sans-serif;
  font-size: var(--p);
  font-weight: var(--Cuerpo);
  color: var(--gris);
}

li {
  font-family: "Poppins-Medium", sans-serif;
  font-size: var(--p);
  color: var(--gris);
}
