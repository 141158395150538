@media (min-width: 321px) {
  .homeTitle2 {
    display: unset;
    width: 100%;
    height: auto;
    font-size: var(--h5);
  }

  #contactPhonesTitle--mobile {
    display: block;
    font-size: var(--p);
    width: 90%;
    height: auto;
    margin-top: 25px;
    margin-bottom: 25px;
  }
}

@media (min-width: 768px) {
  .homeTitlesContainer {
    width: 100%;
  }

  .carruselContainer {
    display: flex;
  }

  .homeTitle2 {
    width: 100%;
    height: auto;
    font-family: "Poppins-Light";
    font-weight: 300;
    font-size: var(--h3);
  }

  .homeTitle1 {
    font-size: var(--h1);
  }

  #contactPhonesTitle--mobile {
    display: none;
  }

  #contactPhonesTitle--desktop {
    display: flex;
    font-size: var(--h3);
  }

  .phonesWrapper {
    width: 70%;
  }

  .serviciosCardsContainer {
    justify-content: center;
    align-items: center;
  }

  .servicioCard {
    width: 70%;
  }

  .mapGalleryContainer {
    width: 80%;
  }

  .googleMapSmall {
    display: none;
  }

  .googleMapBig {
    display: block;
    width: 500px;
    height: auto;
  }

  .historiaContainer {
    width: 80%;
  }

  .argumentCard {
    width: 80%;
  }
}

@media (min-width: 992px) {
  .homeTitlesContainer {
    width: 100%;
  }

  .carruselContainer img {
    width: 30%;
  }

  .homeTitle1 {
    display: none;
  }

  .homeTitle1Desktop {
    display: unset;
  }

  .contactPhonesContainer {
    margin: 0;
  }

  #contactPhonesTitle--mobile {
    display: none;
  }

  #contactPhonesTitle--desktop {
    display: unset;
    font-size: var(--h4);
  }

  .phonesWrapper {
    flex-direction: row;
    width: 50%;
  }

  .phoneButton {
    width: 50%;
  }

  .phoneWhatsapp {
    width: 50%;
  }

  .serviciosCardsContainer {
    flex-direction: row;
  }

  .servicioCard {
    width: 40%;
    min-height: 460px;
  }

  .servicioIncludesGallery {
    flex-direction: row;
  }

  #imagenFloreria {
    width: 200px;
    height: 200px;
  }

  #imagenFloreria2 {
    width: 200px;
    height: 200px;
  }

  .googleMapBig {
    display: block;
    width: 750px;
    height: 400px;
  }

  .nosotrosContainer {
    width: 60%;
  }

  .historiaContainer {
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    align-items: center;
  }

  .historiaTextContainer {
    flex-direction: column;
    width: 40%;
    height: 300px;
    justify-content: space-between;
    align-items: flex-start;
  }

  .historiaText {
    width: 100%;
  }

  .historiaValores {
    width: 100%;
  }

  .historiaImg {
    width: 50%;
  }

  .argumentsContainer {
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
  }

  .argumentCard {
    height: 200px;
    width: 40%;
  }

  .faqsContainer {
    width: 50%;
  }
}
