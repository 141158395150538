.navbarMainContainer {
  display: flex;
  flex-direction: row;
  width: 90%;
  height: auto;
  background-color: var(--cremaMedio) !important;
  justify-content: center;
  align-items: center;
}

.container-fluid {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.logoContainer img {
  width: 200px;
  height: auto;
}

.linksContainer {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: auto;
  justify-content: space-evenly;
  align-items: baseline;
}

.initSessionContainer {
  display: flex;
  flex-direction: row;
  width: 50%;
  height: 100%;
  justify-content: center;
  align-items: center;
}

.initSessionBtn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 90%;
  height: 2.5rem;
  background-color: var(--cafeBase);
  color: var(--blanco);
  border-style: hidden;
  border-radius: 20px;
  padding-left: 1rem;
  padding-right: 1rem;
  gap: 0.5rem;
}

.initSessionBtn:hover {
  color: var(--blanco);
  background-color: var(--gris);
}
